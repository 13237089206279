body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blinking{
  animation:blinkingText 0.4s infinite;
}


@keyframes blinkingText{
  0%{     color: red;     }
  10%{    color: orange;  }
  30%{    color: green;   }
  60%{    color: blue;    }
  90%{   color: purple;   }
  100%{   color: red;     }
}


